<template>
    <div class="container">
        <div>404 NOT FOUND</div>
    </div>
</template>
<script>
export default {
    name:'index',
    components:{},
    data () {
        return {
            
        }
    },
    methods:{
        
    },
    computed:{

    },
    created(){

    },
    mounted () {
        
    },
    beforeDestroy(){
        
    },
    watch:{},
}
</script>
<style lang="less" scoped>
    .container{
        >div{
            text-align: center;
            font-weight: bolder;
            font-size: 40px;
            margin: 100px;
        }
    }
</style>